import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import './SalesMobile.css'; // Import CSS file for styling

const SalesMobile = ({ salesData, setSalesData }) => {
  // STATE
  const [editItem, setEditItem] = useState(null); // To track which item is being edited

  const handleEditClick = (listing, saleId, dropTime) => {
    // Set the sale into edit mode and store the saleId
    setEditItem({
      saleId, // Store the sale's _id for targeting the correct sale
      dropTime: dropTime || moment().format('YYYY-MM-DD HH:mm:ss'), // Default dropTime if not set
    });
  };

  const updateDate = async (e) => {
    e.preventDefault();

    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      
      const saleId = editItem?.saleId;
      const apiUrl = `${beUrl}/update-purchase/${saleId}`; // Use saleId for the API endpoint

      console.log(JSON.stringify(editItem)); // Log the current editItem for debugging

      // Update the specific sale using the sale's _id
      await axios.put(
        apiUrl,
        {
          status: 'Approved',
          dropTime: editItem?.dropTime,
        },
        {
          withCredentials: true,
        }
      );
      
      toast.success('Date Updated Successfully');
    } catch (error) {
      console.error(error);
      toast.error('Error occurred while updating the date');
    }
  };

  // Function to color status text
  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'text-green-500';
      case 'Waiting for Approval':
        return 'text-yellow-500';
      default:
        return 'text-yellow-500';
    }
  };

  // Function to check if any sale has a sellerStatus that is not "Waiting for Approval"
  const shouldHideConfirmButton = (sales) => {
    return sales.some((sale) => sale.sellerStatus !== 'Waiting for Approval');
  };

  return (
    <div>
      <div>
        {salesData?.map((item) => (
          <div key={item._id} className="listing-box">
            {/* Class Name as header for each listing */}
            <h2>{item.className ? `Class: ${item.className}` : 'No Class Name'}</h2>

            <p>Additional Notes: {item?.additionalNotes ? item?.additionalNotes : 'None'}</p>
            <p>Time From: {moment(item?.optimalTimeFrom).format('LLL')}</p>
            <p>Time To: {moment(item?.optimalTimeTo).format('LLL')}</p>

            {/* Iterate over sales array to display buyerStatus */}
            {item.sales?.map((sale) => (
              <div key={sale._id}>
                <p className={getStatusColor(sale.buyerStatus)}>
                  Buyer Status: {sale.buyerStatus}
                </p>
                <p>Seller Status: {sale.sellerStatus}</p>

                {/* Hide Confirm Button if any sale has a sellerStatus that is not "Waiting for Approval" */}
                {!shouldHideConfirmButton(item.sales) && !editItem && (
                  <button
                    onClick={() => handleEditClick(item, sale._id, sale.dropTime)} // Pass sale._id and sale.dropTime
                    className="btn-confirm"
                  >
                    Confirm
                  </button>
                )}

                {/* Show the Text Input for Drop Time when the Confirm button is clicked */}
                {editItem && editItem.saleId === sale._id && ( // Change condition to sale._id
                  <div>
                    <form onSubmit={updateDate}>
                      <div className="input-container">
                        <label htmlFor="dropTime">Drop Time:</label>
                        <input
                          type="text"
                          id="dropTime"
                          value={editItem?.dropTime || ''}
                          onChange={(e) =>
                            setEditItem((prev) => ({
                              ...prev,
                              dropTime: e.target.value, // Manually input the drop time as text
                            }))
                          }
                          placeholder="YYYY-MM-DD HH:mm:ss"
                        />
                      </div>

                      <div>
                        {/* Cancel Button */}
                        <button
                          type="button"
                          onClick={() => setEditItem(null)}
                          className="btn-cancel"
                        >
                          Cancel
                        </button>

                        {/* Save Button */}
                        <button type="submit" className="btn-confirm">
                          Confirm
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalesMobile;
