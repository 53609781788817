// ./pages/Error/Error.js
import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="w-full max-w-md bg-white shadow-md rounded-lg p-6 text-center">
        <h1 className="text-2xl font-bold text-red-600">
          Oops! Page not found.
        </h1>
        <p className="text-gray-700 my-4">
          The page you are looking for does not exist.
        </p>
        <p className="text-gray-700 mb-4">
          If you think this is a mistake, please contact us.
        </p>
        <a
          href="mailto:coursebidder@gmail.com"
          className="text-blue-500 hover:underline"
        >
          Report this issue
        </a>
        <div className="mt-6">
          <Link
            to="/home"
            className="text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded"
          >
            Go to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
