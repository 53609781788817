import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";

// COMPONENTS
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Image from "../../components/designLayouts/Image";
import { toast } from "react-toastify";
// REDUX

const Approvals = () => {
  // INSTANCES
  const navigate = useNavigate();

  // STATES
  const [apiData, setApiData] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(true);

  // API FUNCTION
  const fetchData = async () => {
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://server.coursebidder.com";

      // UPDATE THE API WHEN PROVIDED AND SAVE RESPONSE IN apiData (CURRENTLY DUMMYDATA IS SAVED IN apiData)
      const { data: { listing = [] } = {} } = await axios.get(
        `${beUrl}/get-listing-awaiting-for-approval`,
        {
          withCredentials: true,
        },
      );
      setLoading(false);
      setApiData(listing);
    } catch (error) {
      console.error("Error fetching listings:", error);
      navigate("/home");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const approveItmem = async () => {
    try {
      const itemId = selectedItem._id;
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://server.coursebidder.com";

      const res = await axios.put(
        `${beUrl}/approve-listing/${itemId}`,
        undefined,
        {
          withCredentials: true,
        },
      );
      if (res?.data?.error) {
        toast.error(res?.data?.error);
      }

      toast.success(res?.data?.message);
      setApiData((prev) => {
        return prev?.filter((node) => node?._id !== selectedItem._id);
      });
      setSelectedItem(null);
      setAction(null);
    } catch (error) {
      console.log(error);
      setSelectedItem(null);
      setAction(null);
    }
  };

  const denyItem = async () => {
    try {
      const itemId = selectedItem._id;
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://server.coursebidder.com";
      const res = await axios.put(
        `${beUrl}/deny-listing/${itemId}`,
        undefined,
        {
          withCredentials: true,
        },
      );

      if (res?.data?.error) {
        toast.error(res?.data?.error);
      }

      toast.success(res?.data?.message);
      setApiData((prev) => {
        return prev?.filter((node) => node?._id !== selectedItem._id);
      });
      setSelectedItem(null);
      setAction(null);
    } catch (error) {
      console.log(error);
      setSelectedItem(null);
      setAction(null);
    }
  };

  return (
    <>
      <div className="max-w-container mx-auto px-4">
        <Breadcrumbs title="Approvals" undercard="Courses to be approved" />
        <div className="overflow-auto user-list-scroll-bar  mb-10">
          <div className="pb-10 min-w-[1340px]">
            <div className="w-full h-20 bg-[#F5F7F7] text-primeColor grid grid-cols-12 place-content-center px-6 text-lg font-titleFont font-semibold">
              <h2 className="col-span-5">Listing Information</h2>
              <h2 className="col-span-2">Price</h2>
              <h2 className="col-span-2">Status</h2>
              <h2 className="col-span-3">Actions</h2>
            </div>
            <div className="mt-5">
              {loading && (
                <div className="w-full flex items-center justify-center py-20">
                  <div className="loader"></div>
                </div>
              )}
              {apiData?.length === 0 && (
                <div className="w-full flex items-center justify-center py-6">
                  <p className="text-lg font-bold ">
                    No listings to display For Approval
                  </p>
                </div>
              )}

              {apiData?.map((item) => (
                <motion.div
                  key={item._id}
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  className="grid grid-cols-12 justify-between items-center bg-white p-6 rounded-md shadow-md mb-4 text-lg"
                >
                  <div className="col-span-5 flex gap-4 items-center justify-start">
                    <div>
                      {item.verificationImage && (
                        <Image
                          imgSrc={item.verificationImage}
                          className="w-[200px] h-[100px]"
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="font-semibold text-gray-800">
                        {item.isClassListing
                          ? `Class: ${item.className}`
                          : "Offering to Hold Class"}
                      </p>
                      <p className="text-gray-600">
                        Additional Notes: {item.notes}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <p className="font-semibold text-gray-800">${item.price}</p>
                  </div>
                  <div className="col-span-2">
                    <p
                      className={`font-bold bg-yellow-500 text-white px-2 py-1 rounded-xl w-fit`}
                    >
                      {item.status}
                    </p>
                  </div>
                  <div className="col-span-3 flex space-x-4">
                    <button
                      onClick={() => {
                        setSelectedItem(item);
                        setAction("approve");
                        setConfirmationModal(true);
                      }}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => {
                        setSelectedItem(item);
                        setAction("deny");
                        setConfirmationModal(true);
                      }}
                      className="bg-red-500 text-white px-4 py-2 rounded-md"
                    >
                      Deny
                    </button>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={confirmationModal}
        onContinue={() => {
          setConfirmationModal(false);
          if (action === "approve") {
            approveItmem();
          } else if (action === "deny") {
            denyItem();
          }
        }}
        onCancel={() => {
          setConfirmationModal(false);
        }}
      />
    </>
  );
};

export default Approvals;
