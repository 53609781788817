
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import axios from "axios";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { emptyListings, emptyCart } from "../../assets/images/index";
import clsx from "clsx";
import Sales from "../../components/Sales/Sales";
import SalesDataHandler from '../../components/Sales/SalesDataHandler';
import useMediaQuery from "../../hooks/useMediaQuery"; // Import the custom hook
import SalesMobile from '../../components/Sales/SalesMobile'; // Import the SalesMobile component


function getListings() {
  const beUrl = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : 'https://server.coursebidder.com';

  return fetch(`${beUrl}/numlistings`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((profileData) => profileData)
    .catch((error) => console.error('Error fetching listings:', error));
}

const UserListings = () => {

  const isMobile = useMediaQuery("(max-width: 768px)"); // Detect if screen width is less than or equal to 768px

  const [toggle, setToggle] = useState("");

  const [apiData, setApiData] = useState([]);
  const [removingAll, setRemovingAll] = useState(false);
  const [numListings, setNumListings] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [visibleListing, setVisibleListing] = useState([]); 

  useEffect(() => {
    getListings().then((profileData) => {
      if (profileData) {
        setNumListings(profileData.num);
      } else {
        setNumListings(0);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : 'https://server.coursebidder.com';

        const response = await axios.get(`${beUrl}/getListingsFromUser`, {
          withCredentials: true,
        });
        setApiData(response.data.data);

        
        if (apiData.length > 0) {
          // Process the sales data for each item in apiData using SalesDataHandler
          const allProcessedSales = apiData.map((item) => {
            // Option 1: If SalesDataHandler is a hook or a function
            const { getSalesData } = SalesDataHandler({ salesData: item.sales });
            
            return {
              itemId: item._id,
              salesData: getSalesData(), // Retrieve processed sales data]
            
            };
          });
         // setProcessedSalesList(allProcessedSales);
        }
        
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };
    fetchData();
  }, [removingAll]);

  const handleEditClick = (item) => {
    setEditItem(item);
  };

  const handleDeleteClick = async (item) => {
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      await axios.post(
        `${beUrl}/deleteListing`,
        { listingId: item._id },
        { withCredentials: true }
      );
      setRemovingAll(!removingAll); // Trigger re-fetch of listings
    } catch (error) {
      console.error('Error deleting listing:', error);
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      await axios.post(
        `${beUrl}/updateListing`,
        {
          listingId: editItem._id,
          collegeName: editItem.collegeName,
          price: editItem.price,
          notes: editItem.notes,
        },
        { withCredentials: true }
      );
      setEditItem(null);
      setRemovingAll(!removingAll); // Trigger re-fetch of listings
    } catch (error) {
      console.error('Error updating listing:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditItem((prev) => ({ ...prev, [name]: value }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Available':
        return 'text-green-500';
      case 'Pending':
        return 'text-yellow-500';
      case 'Sold':
        return 'text-red-500';
      case 'Approved':
        return 'text-green-500';
      case 'Denied':
        return 'text-red-500';
      case 'Waiting for Approval':
        return 'text-yellow-500';
      default:
        return 'text-gray-500';
    }
  };

  const handleToggle = (e) => {
    setToggle(toggle === e ? '' : e);
  };
  const handleToggleOther = (listingId) => {
    // Toggle between showing and hiding sales data
    setVisibleListing(visibleListing === listingId ? null : listingId);
  };
  if (isMobile) {
    return (
      <div>
        <h1>Sales Data</h1>
        {/* Pass the data and setter to SalesMobile */}
        <SalesMobile salesData={apiData} setSalesData={setApiData} />
      </div>
    );

         
          }else{

  // Regular desktop layout
  return (

    <div className="max-w-[90vw] mx-auto px-4">
      <Breadcrumbs title="My Listings" undercard="Your listed courses to sell" />
      {numListings === null ? (
        <div className="w-full flex items-center justify-center py-[10vh]">
          <div className="loader"></div>
        </div>
      ) : numListings > 0 ? (
        <div className="overflow-auto user-list-scroll-bar mb-10">
          <div className="pb-10 min-w-[85vw]">
            <div className="overflow-x-auto h-[5vh] bg-[#dce4e4] text-primeColor grid grid-cols-12 place-content-center px-6 text-base sm:text-lg font-titleFont font-semibold">
              <h2 className="col-span-4">Listing Information</h2>
              <h2 className="col-span-2">Price</h2>
              <h2 className="col-span-2">Status</h2>
              <h2 className="col-span-4">Actions</h2>

            </div>
            <div className='mt-5'>
              {apiData.map((item) => (
                <div key={item._id}>
                  <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}

                    className="overflow-x-auto grid grid-cols-12 justify-between items-center bg-white p-6 rounded-md shadow-md mb-4 text-base sm:text-lg"

                  >
                    <div className='col-span-4 flex items-center gap-4'>
                      {item.sales.length > 0 && (
                        <button
                          onClick={() => handleToggle(item?._id)}
                          className={clsx(
                            'transition-all duration-300',
                            toggle === item?._id ? 'rotate-180' : 'rotate-0'
                          )}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='25'
                            viewBox='0 0 24 25'
                            fill='none'
                          >
                            <rect
                              y='0.5'
                              width='24'
                              height='24'
                              rx='12'
                              fill='#1E3050'
                            />
                            <path
                              d='M6 9.5L12 15.5L18 9.5'
                              stroke='white'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </button>
                      )}
                      <div>
                        <p className='font-semibold text-gray-800'>
                          {item.isClassListing
                            ? `Class: ${item.className}`
                            : 'Offering to Hold Class'}
                        </p>
                        <p className='text-gray-600'>
                          Additional Notes: {item.notes}
                        </p>
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <p className='font-semibold text-gray-800'>
                        ${item.price}
                      </p>
                    </div>
                    <div className='col-span-2'>
                      <p className={`font-bold ${getStatusColor(item.status)}`}>
                        {item.status.includes('Pending')
                          ? 'Pending'
                          : item.status}
                      </p>
                    </div>
                    <div className='col-span-4 flex space-x-4'>
                      {item?.sales?.length === 0 ? (
                        <>
                          <button
                            disabled={item?.sales?.length > 0}
                            onClick={() => handleEditClick(item)}
                            className='bg-blue-500 text-white px-4 py-2 rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed'
                          >
                            Edit
                          </button>
                          <button
                            disabled={item?.sales?.length > 0}
                            onClick={() => handleDeleteClick(item)}
                            className='bg-red-500 text-white px-4 py-2 rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed'
                          >
                            Delete
                          </button>
                        </>
                      ) : (
                        <p className='font-semibold text-gray-800'>None</p>
                      )}
                    </div>
                  </motion.div>
                  {toggle === item?._id && (

                    <div className="w-full">
                      <div className="overflow-x-auto min-h-[4vh] bg-[#F5F7F7] text-primeColor grid grid-cols-12 gap-4 sm:gap-16 justify-between place-content-center px-6 text-sm sm:text-lg font-titleFont font-semibold">
                        <h2 className="col-span-2 text-lg text-lightText">

                          Additional Notes
                        </h2>
                        <h2 className="col-span-1 text-lg text-lightText">
                          Time From
                        </h2>
                        <h2 className="col-span-1 text-lg text-lightText">
                          Time To
                        </h2>

                        <h2 className="col-span-2 text-lg text-lightText">
                          Updated At

                        </h2>
                        <h2 className="col-span-2 text-lg text-lightText">
                          Drop Time
                        </h2>
                        <h2 className="col-span-2 text-lg text-lightText">
                          Buyer Status
                        </h2>
                        <h2 className='col-span-2 text-lg text-lightText'>
                          Actions
                        </h2>
                      </div>
                      {item?.sales?.length > 0 ? (

                        <div className="mt-5">
            <Sales
                            salesData={item?.sales}
                            setSalesData={setApiData}
                          />
                        </div>
                      ) : (
                        <motion.div
                          initial={{ y: 30, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className='flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20'
                        >
                          <div>
                            <img

                              className="w-4/5 rounded-lg p-4 mx-auto"

                              src={emptyCart}
                              alt='emptyCart'
                            />
                          </div>

                          <div className="max-w-[85vw] sm:max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md">
                            <h1 className="font-titleFont text-xl font-bold uppercase">

                              No classes in sales.
                            </h1>
                          </div>
                        </motion.div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className='flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20'
        >
          <div>
            <img

              className="w-4/5 rounded-lg p-4 mx-auto"

              src={emptyListings}
              alt='emptyListings'
            />
          </div>

          <div className="max-w-[85vw] sm:max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">

              No current listings.
            </h1>
            <p className='text-sm text-center px-10 -mt-2'>
              Click Add Listing above to list your first class!
            </p>
            <Link to='/home'>
              <button className='bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300'>
                Continue Searching
              </button>
            </Link>
          </div>
        </motion.div>
      )}

      {editItem && (
        <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center'>
          <form
            onSubmit={handleEditSubmit}

            className="bg-white p-6 rounded-md shadow-md text-lg w-[80vw] max-w-[500px]"

          >
            <h2 className='text-2xl font-bold mb-4'>Edit Listing</h2>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>
                College Name
              </label>
              <input
                type='text'
                name='collegeName'
                value={editItem.collegeName}
                onChange={handleChange}
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>
                Price
              </label>
              <input
                type='number'
                name='price'
                value={editItem.price}
                onChange={handleChange}
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>
                Additional Notes
              </label>
              <textarea
                name='notes'
                value={editItem.notes}
                onChange={handleChange}
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div className='flex justify-end'>
              <button
                type='button'
                onClick={() => setEditItem(null)}
                className='mr-2 bg-gray-500 text-white px-4 py-2 rounded-md'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='bg-blue-500 text-white px-4 py-2 rounded-md'
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
}
export default UserListings;
