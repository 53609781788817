import React from "react";
import { Link } from "react-router-dom";
import { FaUserShield, FaHandshake, FaLock } from "react-icons/fa";

const LandingPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <main className="flex-1 w-full flex flex-col items-center pt-18">
        <section className="text-center my-20 w-full px-4">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-5xl lg:text-6xl font-extrabold text-gray-900 mb-6">
              Welcome to CourseBidder!
            </h1>
            <p className="mt-4 text-2xl text-gray-700 max-w-2xl mx-auto">
              An anonymous and secure platform for buying and selling class
              seats. Preventing scams, enhancing student-to-student private
              transactions.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <Link to="/home">
                <button className="px-10 py-5 bg-blue-600 text-white rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105 flex items-center text-2xl">
                  Take Me to Classes
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section className="w-full bg-gray-50 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-4xl font-extrabold text-gray-900 mb-6">
              Our Promise
            </h2>
            <div className="flex flex-col md:flex-row justify-around items-center space-y-8 md:space-y-0">
              <div className="max-w-sm mx-auto flex flex-col items-center">
                <FaUserShield className="text-blue-600 text-6xl mb-4" />
                <p className="text-xl text-gray-500">
                  CourseBidder ensures all transactions are anonymous and
                  secure, preventing scams and protecting students.
                </p>
              </div>
              <div className="max-w-sm mx-auto flex flex-col items-center">
                <FaHandshake className="text-blue-600 text-6xl mb-4" />
                <p className="text-xl text-gray-500">
                  We prioritize student-to-student communication and guarantee a
                  safe platform for exchanging class seats.
                </p>
              </div>
              <div className="max-w-sm mx-auto flex flex-col items-center">
                <FaLock className="text-blue-600 text-6xl mb-4" />
                <p className="text-xl text-gray-500">
                  Our platform is designed to protect your privacy and ensure
                  that all transactions are handled securely.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full bg-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-4xl font-extrabold text-gray-900 mb-6">
              Our Mission
            </h2>
            <p className="max-w-5xl mx-auto text-2xl text-gray-500">
              Our mission is to create a trustworthy platform that connects
              students through secure and anonymous transactions. We aim to
              reduce the stress of class registration and provide opportunities
              for students to support each other.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
