import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import moment from 'moment';

const Sales = ({ salesData, setSalesData }) => {
  console.log(salesData);

  // STATE
  const [editItem, setEditItem] = useState(null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'text-green-500';
      case 'Waiting for Approval':
        return 'text-yellow-500';
      default:
        return 'text-yellow-500';
    }
  };
  const handleEditClick = (item) => {
    setEditItem(item);
    console.log(item);
  };
  const isValidDate = (current) => {
    if (!editItem) return false;
    const { optimalTimeFrom, optimalTimeTo } = editItem;
    if (!optimalTimeFrom || !optimalTimeTo) return false;

    const from = Datetime.moment(optimalTimeFrom).startOf('day');
    const to = Datetime.moment(optimalTimeTo).endOf('day');
    return current.isBetween(from, to, null, '[]');
  };

  const updateDate = async (e) => {
    e.preventDefault();
    console.log(editItem.dropTime);
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      const apiUrl = `${beUrl}/update-purchase/${editItem?._id}`;
      console.log(JSON.stringify(editItem))

      console.log(apiUrl)
      await axios.put(
        apiUrl,
        {
          status: 'Approved',
          dropTime: editItem?.dropTime,
        },
        {
          withCredentials: true,
        }
      );
      toast.success('Date Updated Successfully');

      setSalesData((prev) =>
        prev.map((listing) => {
          if (listing.sales?.length > 0) {
            const updatedSales = listing.sales.map((soldItem) => {
              if (soldItem._id === editItem._id) {
                return {
                  ...soldItem,
                  sellerStatus: 'Approved',
                  buyerStatus: 'Waiting for Approval',
                  dropTime: editItem?.dropTime,
                };
              }
              return soldItem;
            });
            return { ...listing, sales: updatedSales };
          }
          return listing; // Return the listing as is if sales array is empty
        })
      );
        
      setEditItem(null);
    } catch (error) {
      console.log(error);
      toast.error('Error occurred while updating the date');
    }
  };

  const paymentStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return 'text-green-500';
      case 'failed':
        return 'text-yellow-500';
      default:
        return 'text-yellow-500';
    }
  };

  return (
    <>
      <div className='max-w-container mx-auto px-4'>
        <div className='mt-5'>
          {salesData?.map((item) => (
            <motion.div
              key={item._id}
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.4 }}
              className='gap-8 grid grid-cols-12 justify-between items-center bg-white p-6 rounded-md shadow-md mb-4 text-lg'
            >
              <div className='col-span-2'>
                <p className='font-semibold text-gray-800'>
                  {item?.additionalNotes ? item?.additionalNotes : 'None'}
                </p>
              </div>
              <div className='col-span-1'>
                <p className='font-semibold text-gray-800'>
                  {moment(
                    item?.optimalTimeFrom && item?.optimalTimeFrom
                  ).format('LLL')}
                </p>
              </div>
              <div className='col-span-1'>
                <p className='font-semibold text-gray-800'>
                  {moment(item?.optimalTimeTo && item?.optimalTimeTo).format(
                    'LLL'
                  )}
                </p>
              </div>
              <div className='col-span-2'>
                {item?.updatedAt ? (
                  <p className='font-semibold text-gray-800 text-center'>
                    {moment(item?.updatedAt).format('LLL')} <br />
                    {item?.updatedBy?.username &&
                      'by' + item?.updatedBy?.username}{' '}
                  </p>
                ) : (
                  <p className='text-center'>None</p>
                )}
              </div>
              <div className='col-span-2'>
                <p className='font-semibold text-gray-800'>
                  {item?.dropTime
                    ? moment(item?.dropTime && item?.dropTime).format(
                        'LLL'
                      )
                    : 'None'}
                </p>
              </div>

              <div className='col-span-2'>
                <p className={`font-bold ${getStatusColor(item?.buyerStatus)}`}>
                  {item?.buyerStatus}
                </p>
              </div>
              <div className='col-span-2 flex flex-col gap-2  lg:flex-row  lg:space-x-2 '>
                <button
                  disabled={item?.sellerStatus === 'Approved'}
                  onClick={() => handleEditClick(item)}
                  className={`${item?.buyerStatus === 'Approved' && item?.sellerStatus === 'Approved'  ? 'hidden' : 'block'} bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer  disabled:bg-gray-400 disabled:cursor-not-allowed`}
                >
                  Confirm
                </button>
                <p
                  className={`text-left ${item?.buyerStatus === 'Approved' && item?.sellerStatus === 'Approved' && item?.transactionStatus === null ? 'block' : 'hidden'} font-semibold text-gray-800`}
                >
                   Waiting For Payment
                 
                </p>
                <p
                  className={`font-bold ${paymentStatusColor(item?.transactionStatus)}`}
                >
                  {item?.transactionStatus
                    ?.toLowerCase()
                    ?.split(' ')
                    ?.map(function (word) {
                      return word
                        .charAt(0)
                        .toUpperCase()
                        .concat(word.substr(1));
                    })
                    .join(' ')}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
        {editItem && (
          <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center'>
            <form className='bg-white p-6 rounded-md shadow-md text-lg'>
              <h2 className='text-2xl font-bold mb-4'>Edit Listing</h2>
              <div className='mb-4'>
                <label className='block text-gray-700 text-sm font-bold mb-2'>
                  Drop Time:
                </label>
                <Datetime
                  className='border border-gray-300 rounded-md p-2'
                  value={editItem?.dropTime ? moment(editItem.dropTime) : null}
                  onChange={(date) => {
                    if (date && moment.isMoment(date)) {
                      const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
                      setEditItem((prev) => ({
                        ...prev,
                        dropTime: formattedDate,
                      }));
                    }
                  }}
                  // inputProps={{ id: "optimal-time-start", style: styles.input }}
                  isValidDate={isValidDate}
                />
              </div>
              <div className='flex justify-end'>
                <button
                  type='button'
                  onClick={() => setEditItem(null)}
                  className='mr-2 bg-gray-500 text-white px-4 py-2 rounded-md'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='bg-blue-500 text-white px-4 py-2 rounded-md'
                  onClick={(e) => updateDate(e)}
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Sales;
