import axios from "axios";
import React, { useState, useEffect } from "react";
import { ImCross } from "react-icons/im";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { emptyCart } from "../../assets/images";

const Cart = ({ cartEnable, setCartEnable, setProfileData, profileData }) => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchData = async () => {
    const beUrl = process.env.REACT_APP_BACKEND_URL
      ? process.env.REACT_APP_BACKEND_URL
      : "https://server.coursebidder.com";
    const response = await axios.get(`${beUrl}/cart`, {
      withCredentials: true,
    });
    console.log("response: ", response);
    if (response.status === 201) {
      setCartItems(response?.data?.cartItems);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [profileData?.cart]);
  const resetCarthandler = async () => {
    const beUrl = process.env.REACT_APP_BACKEND_URL
      ? process.env.REACT_APP_BACKEND_URL
      : "https://server.coursebidder.com";
    const response = await axios.put(
      `${beUrl}/reset-cart`,
      {},
      {
        withCredentials: true,
      },
    );
    console.log("response: ", response);
    if (response.status === 200) {
      setCartItems([]);
      setProfileData((prev) => {
        return {
          ...prev,
          ["cart"]: [],
        };
      });
      setLoading(false);
    }
  };

  return (
    <div className="absolute top-28  h-screen overflow-auto right-0 w-full md:w-[70%] xl:w-2/5 border-2 bg-white p-5">
      <div className="flex justify-end">
        <ImCross
          className="text-black cursor-pointer"
          onClick={() => setCartEnable(false)}
        />
      </div>
      {loading ? (
        <div role="status">
          <svg
            aria-hidden="true"
            class="block mx-auto w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        cartItems && (
          <div className="max-w-container mx-auto px-4 mt-5">
            {cartItems.length > 0 ? (
              <div className="pb-20 text-black">
                <div className="w-full h-20 bg-[#F5F7F7] text-primeColor  flex justify-between place-content-center gap-2 p-6 text-lg font-titleFont font-semibold">
                  <h2 className="leading-none col-span-2 text-[14px] sm:text-[16px] md:text-lg">
                    Listing Information
                  </h2>
                  <h2 className="leading-none text-[14px] sm:text-[16px] md:text-lg">
                    Price
                  </h2>
                  <h2 className="leading-none text-[14px] sm:text-[16px] md:text-lg">
                    Remove Item
                  </h2>
                </div>
                <div className="mt-5">
                  <ItemCards
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    setProfileData={setProfileData}
                    profileData={profileData}
                  />
                </div>
                <div className="flex justify-between mt-5">
                  <button
                    onClick={resetCarthandler}
                    className="py-2 px-10 bg-red-500 text-white font-semibold leading-none text-[14px] sm:text-[16px] md:text-lg uppercase mb-4 hover:bg-red-700 duration-300"
                  >
                    Reset cart
                  </button>
                  <button
                    onClick={() => {
                      setCartEnable(false);
                      // setProfileData(prev=>{
                      //   return {
                      //     ...prev,
                      //     ['cart']: []
                      //   }
                      // })
                      navigate("/purchasepage", {
                        state: cartItems,
                      });
                    }}
                    className="py-2 px-10 bg-blue-500 text-white font-semibold leading-none text-[14px] sm:text-[16px] md:text-lg uppercase mb-4 hover:bg-blue-700 duration-300"
                  >
                    Buy Items
                  </button>
                </div>
              </div>
            ) : (
              <motion.div
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
              >
                <div>
                  <img
                    className="w-80 rounded-lg p-4 mx-auto"
                    src={emptyCart}
                    alt="emptyCart"
                  />
                </div>
                <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
                  <h1 className="font-titleFont text-xl font-bold uppercase">
                    No classes in cart.
                  </h1>
                  <p className="text-sm text-center px-10 -mt-2">
                    Fill your shopping cart with any classes you are interested
                    in purchasing!
                  </p>
                  <Link to="/">
                    <button
                      onClick={() => setCartEnable(false)}
                      className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300"
                    >
                      Continue Searching
                    </button>
                  </Link>
                </div>
              </motion.div>
            )}
          </div>
        )
      )}
      {/* {cartItems && (
        <div className="max-w-container mx-auto px-4">
          {cartItems.length > 0 ? (
            <div className="pb-20 text-black">
              <div className="w-full h-20 bg-[#F5F7F7] text-primeColor  flex justify-between place-content-center gap-2 p-6 text-lg font-titleFont font-semibold">
                <h2 className="leading-none col-span-2 text-[14px] sm:text-[16px] md:text-lg">
                  Listing Information
                </h2>
                <h2 className="leading-none text-[14px] sm:text-[16px] md:text-lg">
                  Price
                </h2>
                <h2 className="leading-none text-[14px] sm:text-[16px] md:text-lg">
                  Remove Item
                </h2>
              </div>
              <div className="mt-5">
                <ItemCards cartItems={cartItems} setCartItems={setCartItems} setProfileData={setProfileData} profileData={profileData}/>
              </div>
              <div className="flex justify-between">
                <button
                  onClick={resetCarthandler}
                  className="py-2 px-10 bg-red-500 text-white font-semibold leading-none text-[14px] sm:text-[16px] md:text-lg uppercase mb-4 hover:bg-red-700 duration-300"
                >
                  Reset cart
                </button>
                <button
                  onClick={() => {
                    setCartEnable(false);
                    // setProfileData(prev=>{
                    //   return {
                    //     ...prev,
                    //     ['cart']: []
                    //   }
                    // })
                    navigate("/purchasepage", {
                      state: cartItems,
                    });
                  }}
                  className="py-2 px-10 bg-blue-500 text-white font-semibold leading-none text-[14px] sm:text-[16px] md:text-lg uppercase mb-4 hover:bg-blue-700 duration-300"
                >
                  Buy Items
                </button>
              </div>
              
            </div>
          ) : (
            <motion.div
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.4 }}
              className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
            >
              <div>
                <img
                  className="w-80 rounded-lg p-4 mx-auto"
                  src={emptyCart}
                  alt="emptyCart"
                />
              </div>
              <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
                <h1 className="font-titleFont text-xl font-bold uppercase">
                  No classes in cart.
                </h1>
                <p className="text-sm text-center px-10 -mt-2">
                  Fill your shopping cart with any classes you are interested in
                  purchasing!
                </p>
                <Link to="/">
                  <button onClick={() => setCartEnable(false)} className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                    Continue Searching
                  </button>
                </Link>
              </div>
            </motion.div>
          )}
        </div>
      )} */}
    </div>
  );
};

const ItemCards = ({
  cartItems,
  setCartItems,
  setProfileData,
  profileData,
}) => {
  console.log("Item: ", cartItems);

  const handleRemoveItem = async (item_id) => {
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://server.coursebidder.com";
      const apiUrl = `${beUrl}/remove-item-from-cart`;
      const response = await axios.delete(apiUrl, {
        data: { item_id: item_id }, // Send the body data under 'data'
        withCredentials: true, // Include credentials
      });
      setCartItems((prev) => prev.filter((item) => item._id !== item_id));
      const cart = profileData?.cart;
      const newCart = cart?.filter((item) => item !== item_id);
      setProfileData((prev) => ({ ...prev, ["cart"]: newCart }));
    } catch (error) {
      // alert("Class purchase unsuccessful");
    }
  };

  return cartItems?.map((item) => (
    <div
      key={item._id}
      className="w-full overflow-x-auto border text-primeColor flex justify-between place-content-center gap-6 p-6 text-lg font-titleFont font-semibold"
    >
      <p className="w-[40%] font-semibold text-gray-800 leading-none col-span-2 text-[14px] sm:text-[16px] md:text-lg">
        {item.isClassListing
          ? `Class: ${item.className}`
          : "Offering to Hold Class"}
      </p>

      <p className="w-[22%] leading-none text-[14px] sm:text-[16px] md:text-lg">
        ${item.price}
      </p>
      <div className="w-[14%] text-lg">
        <button onClick={() => handleRemoveItem(item._id)}>
          <MdDeleteOutline className="text-xl hover:text-red-500 " />
        </button>
      </div>
    </div>
  ));
};

export default Cart;
