import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    email: null,
  },
  reducers: {
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { updateEmail } = userSlice.actions;
export const userEmail = (state) => state?.user?.email;
export default userSlice.reducer;
