import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import { useOutletContext } from "react-router-dom";

function EachListing({
  _id,
  email,
  isInCart,
  price,
  timePosted,
  status,
  collegeName,
  isClassListing,
  className,
  notes,
  setProfileData,
  profileData,
}) {
  console.log("item id : ", isInCart);

  const handleAddToCart = async () => {
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://server.coursebidder.com";

      const response = await axios.post(
        `${beUrl}/addToCart`,
        {
          listing_id: _id,
        },
        {
          withCredentials: true,
        },
      );
      console.log(response);
      if (response.status === 201) {
        // console.log(profileData?.cart)
        setProfileData((prev) => {
          return {
            ...prev,
            ["cart"]: [...prev?.cart, _id],
          };
        });
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="bg-gray-200 shadow-md rounded-lg p-6 m-2 flex flex-col justify-between h-80">
      <div>
        <p className="text-xl font-semibold text-blue-600 italic">${price}</p>
        <p className="text-lg text-gray-700 mt-2">Status: {status}</p>
        <p className="text-lg text-gray-700">College: {collegeName}</p>
        <p className="text-lg text-gray-700">
          Type:{" "}
          {isClassListing ? `Class: ${className}` : "Offering to Hold Class"}
        </p>
        <p className="text-lg text-gray-700">Notes: {notes}</p>
        <p className="text-sm text-gray-500 mt-2">
          Time Created: {new Date(timePosted).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </p>

      </div>
      <div className="mt-4">
        <button
          className={`${profileData?.cart?.includes(_id) ? "bg-blue-300 " : "bg-blue-500 hover:bg-blue-600"} text-white py-2 px-4 rounded-full`}
          onClick={() => handleAddToCart()}
          disabled={profileData?.cart?.includes(_id)}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
}

function Items({ currentItems, setProfileData, profileData }) {
  console.log("Items : ", currentItems);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {currentItems &&
        currentItems.map((item) => (
          <EachListing
            key={item._id}
            {...item}
            setProfileData={setProfileData}
            profileData={profileData}
          />
        ))}
    </div>
  );
}

const Home = () => {
  const { profileData, setProfileData } = useOutletContext();

  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({ college: "", type: "", class: "" });
  const [sort, setSort] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleResetFilters = () => {
    setFilters({ college: "", type: "", class: "" });
    setSort("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";

        const response = await axios.get(`${beUrl}/getListings`, {
          withCredentials: true,
        });
        setApiData(response.data.data);
        console.log(response.data.data);
        setFilteredData(response.data.data); // Initially set filtered data to all listings
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    const fetchUserData = async () => {
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";

        const userResponse = await axios.get(`${beUrl}/getUserFromSession`, {
          withCredentials: true,
        });
        setApiData((prev) =>
          prev?.map((item) => {
            const isInCart = userResponse.data?.cart.includes(item._id);
            return {
              ...item,
              isInCart,
            };
          }),
        );
        setUserEmail(userResponse.data.email);
        setUserEmail(userResponse.data.cart);
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    setTimeout(() => {
      setLoading(false);
    }, 500); // Simulate a 0.5 second loading screen

    fetchData();
    fetchUserData();
  }, []);

  useEffect(() => {
    let filtered = apiData
      .filter((item) => item.email !== userEmail)
      .filter(
        (item) => !filters.college || item.collegeName === filters.college,
      )
      .filter(
        (item) =>
          !filters.type ||
          (filters.type === "Class" && item.isClassListing) ||
          (filters.type === "Offer" && !item.isClassListing),
      )
      .filter((item) => !filters.class || item.className === filters.class);

    if (sort === "price") {
      filtered.sort((a, b) => a.price - b.price);
    } else if (sort === "college") {
      filtered.sort((a, b) => a.collegeName.localeCompare(b.collegeName));
    } else if (sort === "type") {
      filtered.sort((a, b) => a.isClassListing - b.isClassListing);
    }

    setFilteredData(filtered);
  }, [filters, sort, apiData, userEmail]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-full py-20">
        <p className="text-2xl font-semibold text-gray-700">Loading...</p>
      </div>
    );
  }

  if (!apiData.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full py-20">
        <p className="text-2xl font-semibold text-gray-700">
          No classes listed yet. Be the first one!
        </p>
      </div>
    );
  }

  const filteredApiDataForFilters = apiData.filter(
    (item) => item.email !== userEmail,
  );

  const colleges = [
    ...new Set(filteredApiDataForFilters.map((item) => item.collegeName)),
  ].map((college) => ({
    name: college,
    count: filteredApiDataForFilters.filter(
      (item) => item.collegeName === college,
    ).length,
  }));

  const types = [
    {
      name: "Class",
      count: filteredApiDataForFilters.filter((item) => item.isClassListing)
        .length,
    },
    {
      name: "Offer",
      count: filteredApiDataForFilters.filter((item) => !item.isClassListing)
        .length,
    },
  ];

  const classes = [
    ...new Set(filteredApiDataForFilters.map((item) => item.className)),
  ].map((className) => ({
    name: className,
    count: filteredApiDataForFilters.filter(
      (item) => item.className === className,
    ).length,
  }));

  console.log(apiData?.length);

  return (
    <div className="flex flex-col">
      <Breadcrumbs
        title="Home - All Course Listings"
        undercard="Search for new listings to purchase"
      />
      <div className="w-[90%] lg:w-[97%] bg-blue-300 relative py-2 rounded-lg mx-auto lg:mx-4 mt-1">
        <div className="px-2 lg:px-4">
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full">
            <div className="flex flex-wrap gap-2 lg:gap-4 mt-1 lg:mt-0 w-full lg:w-auto">
              <div className="w-[45%] lg:w-auto relative">
                <select
                  name="college"
                  value={filters.college}
                  onChange={handleFilterChange}
                  className="w-full lg:w-auto px-3 py-2 rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">All Colleges</option>
                  {colleges.map((college) => (
                    <option key={college.name} value={college.name}>
                      {college.name} ({college.count})
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-[45%] lg:w-auto relative">
                <select
                  name="type"
                  value={filters.type}
                  onChange={handleFilterChange}
                  className="w-full lg:w-auto px-3 py-2 rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">All Listing Types</option>
                  {types.map((type) => (
                    <option key={type.name} value={type.name}>
                      {type.name} ({type.count})
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-[45%] lg:w-auto relative">
                <select
                  name="class"
                  value={filters.class}
                  onChange={handleFilterChange}
                  className="w-full lg:w-auto px-3 py-2 rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">All Classes</option>
                  {classes.map((classItem) => (
                    <option key={classItem.name} value={classItem.name}>
                      {classItem.name} ({classItem.count})
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center justify-end w-full lg:w-auto mt-2 lg:mt-0 gap-4">
              <button
                onClick={handleResetFilters}
                className="w-auto px-3 py-2 text-sm rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Reset Filters
              </button>
              <div className="relative flex items-center">
                <label className="block text-sm font-medium text-gray-700 mr-2">
                  Sort by:
                </label>
                <select
                  value={sort}
                  onChange={handleSortChange}
                  className="w-auto px-3 py-2 text-sm rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">None</option>
                  <option value="price">Price</option>
                  <option value="college">College</option>
                  <option value="type">Type</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-shrink-0 w-full max-h-screen overflow-y-auto px-4">
        <Items
          currentItems={filteredData}
          setProfileData={setProfileData}
          profileData={profileData}
        />
      </div>
    </div>
  );
};

export default Home;
