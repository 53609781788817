import React, { useEffect, useState } from 'react';
import { processSalesData } from './salesProcessor';

const SalesDataHandler = ({ salesData }) => {
  const [processedSales, setProcessedSales] = useState([]);

  useEffect(() => {
    if (salesData?.length > 0) {
      const processedData = processSalesData(salesData);
      setProcessedSales(processedData);
    }
  }, [salesData]);

  // Example getter function to access processed sales data
  const getSalesData = () => {
    return processedSales;
  };

  return {
    getSalesData,
  };
};


export default SalesDataHandler;
