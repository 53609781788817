import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-blue-900 text-white py-6">
      <div className="max-w-container mx-auto flex flex-col items-center">
        <p className="mb-4">© 2024 CourseBidder LLC. All rights reserved</p>
        <ul className="flex space-x-6 mb-4">
          <li>
            <Link to="/terms" className="hover:underline">
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to="/privacy" className="hover:underline">
              Privacy Policy
            </Link>
          </li>
          <li>
            <a href="mailto:coursebidder@gmail.com" className="hover:underline">
              Bug Features/Requests
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
