import React from "react";
import landingImage1 from "../../assets/images/cblogo.png"; // Adjust the image path

const Privacy = () => {
  return (
    <div className="min-h-screen flex flex-col items-center bg-white py-16 px-4">
      <div className="text-center mb-12 w-full px-4 sm:px-6 lg:px-8">
        <h1 className="text-5xl font-bold text-gray-900 mb-6">
          Privacy Policy
        </h1>
        <div className="w-full">
          <div className="mx-auto text-left">
            <p className="mt-4 text-lg text-gray-700 indent-8">
              This privacy policy (“Policy”) describes how CourseBidder, LLC and
              its related companies (“Company”) collect, use, and share personal
              information of consumer users of this website, coursebidder.com
              (the “Site”). This Policy also applies to any of our other
              websites that post this Policy. This Policy does not apply to
              websites that post different statements.
            </p>
            <h2 className="mt-8 text-2xl font-bold text-gray-900">
              WHAT WE COLLECT
            </h2>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              We get information about you in a range of ways.
            </p>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              <strong>Information You Give Us:</strong> We collect your name,
              email address, password, and other information you directly give
              us on our Site.
            </p>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              <strong>Information Automatically Collected:</strong> We
              automatically log information about you and your computer. For
              example, when visiting our Site, we log your computer operating
              system type, browser type, browser language, the website you
              visited before browsing to our Site, pages you viewed, how long
              you spent on a page, access times, and information about your use
              of and actions on our Site.
            </p>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              <strong>Cookies:</strong> We may log information using "cookies."
              Cookies are small data files stored on your hard drive by a
              website. We may use both session cookies (which expire once you
              close your web browser) and persistent cookies (which stay on your
              computer until you delete them) to provide you with a more
              personal and interactive experience on our Site. This type of
              information is collected to make the Site more useful to you and
              to tailor the experience with us to meet your special interests
              and needs.
            </p>
            <h2 className="mt-8 text-2xl font-bold text-gray-900">
              USE OF PERSONAL INFORMATION
            </h2>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              We use your personal information as follows:
            </p>
            <ul className="mt-4 text-lg text-gray-700 list-disc list-inside indent-8">
              <li>
                To operate, maintain, and improve our sites, products, and
                services.
              </li>
              <li>
                To send information including confirmations, invoices, technical
                notices, updates, security alerts, and support and
                administrative messages.
              </li>
              <li>
                To communicate about promotions, upcoming events, and other news
                about products and services offered by us and our selected
                partners.
              </li>
              <li>
                To protect, investigate, and deter against fraudulent,
                unauthorized, or illegal activity.
              </li>
            </ul>
            <h2 className="mt-8 text-2xl font-bold text-gray-900">
              SHARING OF PERSONAL INFORMATION
            </h2>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              We may share personal information as follows:
            </p>
            <ul className="mt-4 text-lg text-gray-700 list-disc list-inside indent-8">
              <li>
                When we do a business deal, or negotiate a business deal,
                involving the sale or transfer of all or a part of our business
                or assets. These deals can include any merger, financing,
                acquisition, or bankruptcy transaction or proceeding.
              </li>
              <li>For legal, protection, and safety purposes.</li>
              <li>To comply with laws.</li>
              <li>To respond to lawful requests and legal processes.</li>
              <li>
                To protect the rights and property of CourseBidder, our agents,
                customers, and others. This includes enforcing our agreements,
                policies, and terms of use.
              </li>
              <li>
                In an emergency. This includes protecting the safety of our
                employees and agents, our customers, or any person.
              </li>
              <li>With those who need it to do work for us.</li>
            </ul>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              We may also share aggregated and/or anonymized data with others
              for their own uses.
            </p>
            <h2 className="mt-8 text-2xl font-bold text-gray-900">
              COOKIE POLICY
            </h2>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              You can typically remove and reject cookies from our Site with
              your browser settings. Many browsers are set to accept cookies
              until you change your settings. If you remove or reject our
              cookies, it could affect how our Site works for you.
            </p>
            <h2 className="mt-8 text-2xl font-bold text-gray-900">
              CONTACT INFORMATION
            </h2>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              We welcome your comments or questions about this privacy policy.
              You may also contact us at privacy@coursebidder.com.
            </p>
            <h2 className="mt-8 text-2xl font-bold text-gray-900">
              CHANGES TO THIS PRIVACY POLICY
            </h2>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              We may change this privacy policy. If we make any changes, we will
              change the Last Updated date below.
            </p>
            <p className="mt-4 text-lg text-gray-700 indent-8">
              Last Updated: July 30, 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
