import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment'; // Import moment

const PurchaseForm = ({ listingDetails, setProfileData }) => {
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState(
    listingDetails?.map((listing) => ({
      listingId: listing._id,
      optimalTimeFrom: moment().format('YYYY-MM-DD HH:mm:ss'),
      optimalTimeTo: moment().format('YYYY-MM-DD HH:mm:ss'),
      additionalNotes: '',
    }))
  );

  const [toggleForAll, setToggleForAll] = useState(false);

  const handlePurchase = async (e) => {
    e.preventDefault();

    const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
    const invalidDate = formDetails.find(
      (listing) =>
        listing.optimalTimeFrom === currentDate ||
        listing.optimalTimeTo === currentDate ||
        moment(listing.optimalTimeFrom).isAfter(moment(listing.optimalTimeTo))
    );

    if (invalidDate) {
      toast.error('Please enter a valid date.');
      return;
    }

    try {
      const beUrl =
        process.env.REACT_APP_BACKEND_URL || 'https://server.coursebidder.com';
      const apiUrl = `${beUrl}/checkout`;
      const response = await axios.post(
        apiUrl,
        { listings: formDetails },
        { withCredentials: true }
      );
      navigate('/purchases');
      setProfileData((prev) => ({ ...prev, cart: [] }));
      console.log('Response: ', response);
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const isValidDate = (current) => {
    // Disable past dates
    return current.isAfter(Datetime.moment().subtract(1, 'day'));
  };

  const styles = {
    container: {
      maxWidth: '400px',
      margin: '0 auto',
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      backgroundColor: '#f9f9f9',
    },
    heading: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    formGroup: {
      marginBottom: '15px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
    },
    input: {
      width: '100%',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    button: {
      width: '100%',
      padding: '10px',
      marginTop: '10px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
    stripeButton: {
      backgroundColor: '#28a745',
    },
    stripeButtonHover: {
      backgroundColor: '#218838',
    },
  };

  const handleInputChange = (listingId, key, value, forAll) => {
    setFormDetails((prev) => {
      const updatedDetails = prev.map((detail) => {
        if (forAll) {
          return { ...detail, [key]: value };
        }
        if (detail.listingId === listingId) {
          return { ...detail, [key]: value };
        }
        return detail;
      });
      return updatedDetails;
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='min-h-screen'>
      {listingDetails?.length > 1 && (
        <div className='w-full p-4 flex'>
          <label className='text-gray-700'>
            Toggle for All
            <input
              type='checkbox'
              checked={toggleForAll}
              className='sr-only peer m-2'
              onChange={() => setToggleForAll((prev) => !prev)}
            />
            <div className="m-2 dark:bg-gray-700 relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
      )}
      {toggleForAll ? (
        <div className='m-4'>
          <div className='w-full relative'>
            <div className='pb-20'>
              <div className='w-full h-20 bg-[#F5F7F7] text-primeColor grid grid-cols-12 place-content-center gap-3 px-6 text-lg font-titleFont font-semibold'>
                <h2 className='col-span-4 md:text-lg text-sm'>
                  Listing Information
                </h2>
                <h2 className='col-span-2 md:text-lg text-sm'>Price</h2>
                <h2 className='col-span-2 md:text-lg text-sm'>Status</h2>
                <h2 className='col-span-4 md:text-lg text-sm'>Posted Time</h2>
              </div>
              <div className='mt-5'>
                {listingDetails?.map((item) => (
                  <motion.div
                    key={item._id}
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    className='grid grid-cols-12 gap-3 justify-between items-center bg-white p-6 rounded-md shadow-md mb-4 text-lg'
                  >
                    <div className='col-span-4'>
                      <p className='font-semibold md:text-lg text-sm text-gray-800'>
                        {item.isClassListing
                          ? `Class: ${item.className}`
                          : 'Offering to Hold Class'}
                      </p>
                      <p className='text-gray-600 md:text-lg text-sm'>
                        Additional Notes: {item.notes}
                      </p>
                    </div>
                    <div className='col-span-2'>
                      <p className='font-semibold text-gray-800 md:text-lg text-sm'>
                        ${item.price}
                      </p>
                    </div>
                    <div className='col-span-2'>
                      <p className='font-bold md:text-lg text-sm'>
                        {item.status}
                      </p>
                    </div>
                    <div className='col-span-4'>
                      <p className='font-bold md:text-lg text-sm'>
                        {item.time}
                      </p>
                    </div>
                  </motion.div>
                ))}
                <div className='col-span-2 m-6'>
                  <label
                    style={styles.label}
                    className='font-semibold mt-2 pb-3'
                  >
                    Set Time From
                  </label>
                  <Datetime
                    inputProps={{
                      id: 'optimal-time-start',
                      style: styles.input,
                    }}
                    value={
                      formDetails[0].optimalTimeFrom
                        ? moment(formDetails[0].optimalTimeFrom)
                        : null
                    }
                    onChange={(e) =>
                      handleInputChange(
                        null,
                        'optimalTimeFrom',
                        e.format('YYYY-MM-DD HH:mm:ss'),
                        true
                      )
                    }
                    isValidDate={isValidDate}
                  />
                </div>
                <div className='col-span-2 m-6'>
                  <label
                    style={styles.label}
                    className='font-semibold mt-2 pb-3'
                  >
                    Set Time To
                  </label>
                  <Datetime
                    value={
                      formDetails[0].optimalTimeTo
                        ? moment(formDetails[0].optimalTimeTo)
                        : null
                    }
                    onChange={(e) =>
                      handleInputChange(
                        null,
                        'optimalTimeTo',
                        e.format('YYYY-MM-DD HH:mm:ss'),
                        true
                      )
                    }
                    inputProps={{
                      id: 'optimal-time-end',
                      style: styles.input,
                    }}
                    isValidDate={isValidDate}
                  />
                </div>
                <div className='col-span-2 m-6'>
                  <label
                    style={styles.label}
                    className='font-semibold mt-2 pb-3'
                  >
                    Set Additional Notes
                  </label>
                  <input
                    style={styles.input}
                    type='text'
                    id='additional-notes'
                    value={formDetails[0].additionalNotes}
                    onChange={(e) =>
                      handleInputChange(
                        null,
                        'additionalNotes',
                        e.target.value,
                        true
                      )
                    }
                  />
                </div>
                <div className='w-full flex justify-center'>
                  <button
                    // style={{ ...styles.button, ...styles.stripeButton }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        styles.stripeButtonHover.backgroundColor)
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        styles.stripeButton.backgroundColor)
                    }
                    onClick={(e) => handlePurchase(e)}
                    className='w-fit bg-[#28a745] mx-auto p-3 px-6 rounded-md text-white'
                    type='button'
                  >
                    Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='m-4'>
          <div className='w-full relative'>
            <div className='pb-20'>
              <div className='w-full h-20 bg-[#F5F7F7] text-primeColor grid grid-cols-12 place-content-center gap-3 px-6 text-lg font-titleFont font-semibold'>
                <h2 className='col-span-2 md:text-lg text-sm'>
                  Listing Information
                </h2>
                <h2 className='col-span-2 md:text-lg text-sm'>Price</h2>
                <h2 className='col-span-2 md:text-lg text-sm'>Status</h2>
                <h2 className='col-span-2 md:text-lg text-sm'>Set Time From</h2>
                <h2 className='col-span-2 md:text-lg text-sm'>Set Time End</h2>
                <h2 className='col-span-2 md:text-lg text-sm'>
                  Additional Notes
                </h2>
              </div>
              <div className='mt-5'>
                {listingDetails?.map((item, index) => (
                  <motion.div
                    key={item._id}
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    className='grid grid-cols-12 gap-3 justify-between items-center bg-white p-6 rounded-md shadow-md mb-4 text-lg'
                  >
                    <div className='col-span-2'>
                      <p className='font-semibold md:text-lg text-sm text-gray-800'>
                        {item.isClassListing
                          ? `Class: ${item.className}`
                          : 'Offering to Hold Class'}
                      </p>
                      <p className='text-gray-600 md:text-lg text-sm'>
                        Additional Notes: {item.notes}
                      </p>
                    </div>
                    <div className='col-span-2'>
                      <p className='font-semibold text-gray-800 md:text-lg text-sm'>
                        ${item.price}
                      </p>
                    </div>
                    <div className='col-span-2'>
                      <p className='font-bold md:text-lg text-sm'>
                        {item.status}
                      </p>
                    </div>
                    <div className='col-span-2'>
                      <Datetime
                        inputProps={{
                          id: 'optimal-time-start',
                          style: styles.input,
                        }}
                        value={
                          formDetails[index].optimalTimeFrom
                            ? moment(formDetails[index].optimalTimeFrom)
                            : null
                        }
                        onChange={(e) =>
                          handleInputChange(
                            item._id,
                            'optimalTimeFrom',
                            e.format('YYYY-MM-DD HH:mm:ss'),
                            false
                          )
                        }
                        isValidDate={isValidDate}
                      />
                    </div>
                    <div className='col-span-2'>
                      <Datetime
                        value={
                          formDetails[index].optimalTimeTo
                            ? moment(formDetails[index].optimalTimeTo)
                            : null
                        }
                        onChange={(e) =>
                          handleInputChange(
                            item._id,
                            'optimalTimeTo',
                            e.format('YYYY-MM-DD HH:mm:ss'),
                            false
                          )
                        }
                        inputProps={{
                          id: 'optimal-time-end',
                          style: styles.input,
                        }}
                        isValidDate={isValidDate}
                      />
                    </div>
                    <div className='col-span-2'>
                      <input
                        style={styles.input}
                        type='text'
                        id='additional-notes'
                        value={formDetails[index].additionalNotes}
                        onChange={(e) =>
                          handleInputChange(
                            item._id,
                            'additionalNotes',
                            e.target.value,
                            false
                          )
                        }
                      />
                    </div>
                  </motion.div>
                ))}
                <div className='w-full flex justify-center'>
                  <button
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        styles.stripeButtonHover.backgroundColor)
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        styles.stripeButton.backgroundColor)
                    }
                    onClick={(e) => handlePurchase(e)}
                    className='w-fit bg-[#28a745] m-9 mx-auto p-3 px-6 rounded-md text-white'
                  >
                    Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PurchasePage = () => {
  const location = useLocation();
  const listingDetails = location.state;
  const { setProfileData } = useOutletContext();

  return (
    <div>
      <PurchaseForm
        listingDetails={listingDetails}
        setProfileData={setProfileData}
      />
    </div>
  );
};

export default PurchasePage;
