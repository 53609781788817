import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    maxWidth: "350px",
  },
};

Modal.setAppElement("#root");

function ConfirmationModal({ open, onContinue, onCancel }) {
  return (
    <div>
      <Modal
        isOpen={open}
        onRequestClose={onCancel}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h1 className="text-lg">Do you want to proceed?</h1>
        <div
          style={{
            width: "fit-content",
            marginLeft: "auto",
            display: "flex",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <button
            onClick={onContinue}
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          >
            No
          </button>
        </div>
      </Modal>
    </div>
  );
}

ReactDOM.render(<ConfirmationModal />, document.getElementById("root"));
export default ConfirmationModal;
