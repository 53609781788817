import React from "react";

const Breadcrumbs = ({ title, undercard }) => {
  return (
    <div className="bg-white py-12">
      {" "}
      {/* Increased padding for larger header */}
      <div className="max-w-container mx-auto px-4">
        <h1 className="text-5xl font-titleFont font-bold text-bruinblue">
          {title}
        </h1>{" "}
        {/* Increased font size */}
        {undercard && (
          <p className="text-2xl text-lightText mt-2">{undercard}</p>
        )}{" "}
        {/* Increased font size */}
      </div>
    </div>
  );
};

export default Breadcrumbs;
