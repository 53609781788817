import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Breadcrumbs from '../../components/pageProps/Breadcrumbs';
import { emptyCart } from '../../assets/images';
import axios from 'axios';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import moment from 'moment'; // Import moment

const Purchases = () => {
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const getPurchasedCourses = async () => {
    try {
      setLoading(true);
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      const apiUrl = `${beUrl}/get-purchases`;
      const response = await axios.get(apiUrl, { withCredentials: true });
      setPurchasedCourses(response?.data?.purchasedListings);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPurchasedCourses();
  }, []);

  console.log('Purchased: ', purchasedCourses);
  const handleEditClick = (item) => {
    setEditItem(item);
    console.log(item);
  };

  const updateDate = async (e) => {
    e.preventDefault();
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      const apiUrl = `${beUrl}/update-purchase/${editItem?._id}`;
      await axios?.put(
        apiUrl,
        {
          status: 'Approved',
          updatedStartDate: editItem?.optimalTimeFrom,
          updatedEndDate: editItem?.optimalTimeTo,
        },
        {
          withCredentials: true,
        }
      );
      toast.success('Listing has been updated successfully');
      setPurchasedCourses((prev) => {
        return prev?.map((val) => {
          if (val?._id == editItem?._id) {
            return {
              ...val,
              buyerStatus: 'Approved',
              sellerStatus: 'Waiting for Approval',
              optimalTimeFrom: editItem?.optimalTimeFrom,
              optimalTimeTo: editItem?.optimalTimeTo,
            };
          }
          return val;
        });
      });
      setEditItem(null);
    } catch (error) {
      console.log(error);
      toast.error('Error Occured while updating date');
      setEditItem(null);
    }
  };

  const isValidDate = (current) => {
    // Disable past dates
    return current.isAfter(Datetime.moment().subtract(1, 'day'));
  };

  const confirmBuyerStatus = async (status, item) => {
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      const apiUrl = `${beUrl}/update-purchase/${item?._id}`;
      const result = await axios?.put(
        apiUrl,
        {
          status,
        },
        {
          withCredentials: true,
        }
      );
      toast.success('Listing has approved successfully');
      setPurchasedCourses((prev) => {
        return prev?.map((val) => {
          if (val?._id == item?._id) {
            return {
              ...val,
              buyerStatus: 'Approved',
            };
          }
          return val;
        });
      });
      setEditItem(null);
      console.log(result);
    } catch (error) {
      console.log(error);
      toast.error('Error Occured while updating date');
      setEditItem(null);
    }
  };

  const handlePaymentProccess = async (listingDetails) => {
    try {
      console.log('Listing Details: ', listingDetails);
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : 'https://server.coursebidder.com';
      const apiUrl = `${beUrl}/create-payment-proccess`;
      const response = await axios.post(
        apiUrl,
        { listingDetails },
        { withCredentials: true }
      );
      console.log('Response: ', response);

      const sessionUrl = response.data.url;

      if (sessionUrl) {
        window.location.href = sessionUrl;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);

      // Extract error message from backend response
      const backendErrorMessage =
        error.response?.data?.error || 'An unknown error occurred';

      // Show the error message as a toast
      toast.error(`Error creating checkout session: ${backendErrorMessage}`);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'text-green-500';
      case 'Waiting for Approval':
        return 'text-yellow-500';
      default:
        return 'text-yellow-500';
    }
  };

  const paymentStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return 'text-green-500';
      case 'failed':
        return 'text-yellow-500';
      default:
        return 'text-yellow-500';
    }
  };

  return (
    <div className='max-w-container mx-auto px-4'>
      <Breadcrumbs title='Purchases' />
      {loading ? (
        <div className='w-full flex items-center justify-center py-20'>
          <div className='loader'></div>
        </div>
      ) : purchasedCourses?.length > 0 ? (
        <div className='overflow-auto user-list-scroll-bar  mb-10'>
          <div className='pb-10 min-w-[1300px]'>
            <div className='gap-16 overflow-x-auto grid grid-cols-12 justify-between items-center p-6 rounded-md shadow-md mb-4 text-lg bg-[#F5F7F7] text-primeColor place-content-center px-6 font-titleFont font-semibold'>
              <h2 className='col-span-2 text-lg'>Listing Information</h2>
              <h2 className='col-span-1 text-lg whitespace-nowrap'>
                Time From
              </h2>
              <h2 className='col-span-1 text-lg whitespace-nowrap'>Time To</h2>
              <h2 className='col-span-2 text-lg'>Update At</h2>
              <h2 className='col-span-2 text-lg'>Drop Time</h2>
              <h2 className='col-span-2 text-lg'>Seller Status</h2>
              <h2 className='col-span-2 text-lg'>Actions</h2>
            </div>
            <div className='mt-5'>
              {/* {products.map((item) => (
                      <div key={item._id}>
                        <ItemCard
                          item={item}
                          uniqueIdentifier={`${item.email}_${item.itemID}`}
                        />
                      </div>
                    ))} */}
              {purchasedCourses?.map((item) => (
                <motion.div
                  key={item._id}
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.4 }}
                  className='gap-12 grid grid-cols-12 justify-between items-center bg-white p-6 rounded-md shadow-md mb-4 text-lg'
                >
                  <div className='col-span-2'>
                    <p className='font-semibold text-gray-800'>
                      {item.listingId.isClassListing
                        ? `Class: ${item.listingId.className}`
                        : 'Offering to Hold Class'}
                    </p>

                    {/* <p className="font-semibold text-gray-800">
                    College Name: {item.listingId.collegeName}
                  </p> */}
                    <p className='text-gray-600'>
                      Additional Notes: {item.listingId.notes}
                    </p>
                    <p className='text-gray-600'>
                      Price: {item.listingId.price}
                    </p>
                  </div>
                  <div className='col-span-1'>
                    <p className='font-semibold text-gray-800 '>
                      {moment(item?.optimalTimeFrom).format(
                        'LLL'
                      )}
                    </p>
                  </div>
                  <div className='col-span-1'>
                    <p className='font-semibold text-gray-800 '>
                      {moment(item?.optimalTimeTo).format(
                        'LLL'
                      )}
                    </p>
                  </div>
                  <div className='col-span-2'>
                    {item?.updatedAt ? (
                      <p className='font-bold text-gray-800  '>
                        {moment(item?.updatedAt).format('LLL')} <br /> by{' '}
                        {item?.updatedBy?.username}{' '}
                      </p>
                    ) : (
                      <p className='font-bold text-gray-800  '>
                      None
                      </p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    {item?.dropTime ? (
                      <p className='font-bold text-gray-800  '>
                        {moment(item?.dropTime).format('LLL')}
                      </p>
                    ) : (
                      <p className='font-bold text-gray-800  '>
                      None
                      </p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <p
                      className={`font-bold ${getStatusColor(item?.sellerStatus)}`}
                    >
                      {item?.sellerStatus}
                    </p>
                  </div>
                  <div className='col-span-2 flex flex-col gap-2  lg:flex-row  lg:space-x-2'>
                    <button
                      disabled={item?.sellerStatus === 'Approved'}
                      onClick={() => handleEditClick(item)}
                      className={`${item?.sellerStatus === 'Approved' ? 'hidden' : 'block'} bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer  disabled:bg-gray-400 disabled:cursor-not-allowed`}
                    >
                      Update
                    </button>
                    <button
                      disabled={item?.buyerStatus === 'Approved'}
                      className={`${item?.buyerStatus === 'Approved' && item?.sellerStatus === 'Approved' ? 'hidden' : 'block'} bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer  disabled:bg-gray-400 disabled:cursor-not-allowed`}
                      onClick={() => confirmBuyerStatus('Approved', item)}
                    >
                      Confirm
                    </button>
                    <button
                      className={clsx(
                        item?.buyerStatus === 'Approved' &&
                          item.sellerStatus === 'Approved' &&
                          item?.transactionStatus !== 'paid'
                          ? 'block'
                          : 'hidden',
                        'bg-[#28A745] hover:bg-[#34ba53] text-white px-6 w-96 py-2 rounded-md cursor-pointe'
                      )}
                      onClick={() => handlePaymentProccess(item)}
                    >
                      Pay
                    </button>
                    <p
                      className={`font-bold ${paymentStatusColor(item?.transactionStatus)}`}
                    >
                      {item?.transactionStatus
                        ?.toLowerCase()
                        ?.split(' ')
                        ?.map(function (word) {
                          return word
                            .charAt(0)
                            .toUpperCase()
                            .concat(word.substr(1));
                        })
                        .join(' ')}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className='flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20'
        >
          <div>
            <img
              className='w-80 rounded-lg p-4 mx-auto'
              src={emptyCart}
              alt='emptyCart'
            />
          </div>
          <div className='max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg'>
            <h1 className='font-titleFont text-xl font-bold uppercase'>
              No classes in cart.
            </h1>
            <p className='text-sm text-center px-10 -mt-2'>
              Fill your shopping cart with any classes you are interested in
              purchasing!
            </p>
            <Link to='/'>
              <button className='bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300'>
                Continue Searching
              </button>
            </Link>
          </div>
        </motion.div>
      )}

      {editItem && (
        <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center'>
          <form className='bg-white p-6 rounded-md shadow-md text-lg'>
            <h2 className='text-2xl font-bold mb-4'>Edit Listing</h2>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>
                Optimal Drop Time Start:
              </label>
              <Datetime
                className='border border-gray-300 rounded-md p-2'
                value={
                  editItem?.optimalTimeFrom
                    ? moment(editItem.optimalTimeFrom)
                    : null
                }
                onChange={(date) => {
                  if (date && moment.isMoment(date)) {
                    const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
                    setEditItem((prev) => ({
                      ...prev,
                      optimalTimeFrom: formattedDate,
                    }));
                  }
                }}
                isValidDate={isValidDate}
              />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2'>
                Optimal Drop Time End:
              </label>
              <Datetime
                className='border border-gray-300 rounded-md p-2'
                value={
                  editItem?.optimalTimeTo
                    ? moment(editItem.optimalTimeTo)
                    : null
                }
                onChange={(date) => {
                  if (date && moment.isMoment(date)) {
                    const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
                    setEditItem((prev) => ({
                      ...prev,
                      optimalTimeTo: formattedDate,
                    }));
                  }
                }}
                // inputProps={{ id: "optimal-time-start", style: styles.input }}
                isValidDate={isValidDate}
              />
            </div>
            <div className='flex justify-end'>
              <button
                type='button'
                onClick={() => setEditItem(null)}
                className='mr-2 bg-gray-500 text-white px-4 py-2 rounded-md'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='bg-blue-500 text-white px-4 py-2 rounded-md'
                onClick={(e) => updateDate(e)}
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Purchases;
