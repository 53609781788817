import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import colleges from "../../assets/colleges.json";
import Image from "../../components/designLayouts/Image";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import clsx from "clsx";
import sampleVerification from "../../assets/images/sampleVerification.png"; // Importing the sample verification image

const AddListing = () => {
  const [collegeName, setCollegeName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [session, setSession] = useState("");
  const [loading, setLoading] = useState(true);
  const [isClassListing, setIsClassListing] = useState(false);
  const [className, setClassName] = useState("");
  const [stripeAccounts, setStripeAccounts] = useState();
  const [price, setPrice] = useState("");
  const [notes, setNotes] = useState("");
  const [image, setImage] = useState(null);
  const [allowedNewAccount, setAllowedNewAccount] = useState(false);
  const [stripeAccount, setStripeAccount] = useState();
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const maxSize = 2 * 1024 * 1024;
  const navigate = useNavigate();

  let handlefile = (e) => {
    let selectedFile = e.target.files[0];

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];

    if (!allowedTypes.includes(selectedFile.type)) {
      setFileErrorMessage("File type must be png or jpg");
    } else if (selectedFile.size > maxSize) {
      setFileErrorMessage("File size must be less than 2 MB");
    } else {
      setFileErrorMessage("");
      setImage(selectedFile);
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";

        const {
          data: { session, stripeAccounts },
        } = await axios.get(`${beUrl}/getUserFromSession`, {
          withCredentials: true,
        });
        setSession(session);
        if (stripeAccounts.length > 0) {
          setStripeAccounts(stripeAccounts);
          setStripeAccount(stripeAccounts[0].accountId);
          setAllowedNewAccount(false);
        } else {
          setAllowedNewAccount(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("API call error:", error);
      }
    };
    fetchUserData();
  }, []);

  const resetForm = () => {
    setCollegeName("");
    setIsClassListing(false);
    setClassName("");
    setPrice("");
    setNotes("");
    setImage(null);
  };

  const toggleHandler = () => {
    if (!allowedNewAccount) {
      setStripeAccount(null);
    } else {
      setStripeAccount(stripeAccounts[0].accountId);
    }
    setAllowedNewAccount(!allowedNewAccount);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      if (!stripeAccount) {
        console.log("Some thing went wrong");
        toast.error("Please Enter a Stripe Account ID.");
        return;
      }
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("collegeName", collegeName);
      formData.append("isClassListing", isClassListing);
      formData.append(
        "className",
        isClassListing ? className : "HOLDING_CLASS",
      );
      formData.append("price", parseFloat(price));
      formData.append("notes", notes);
      formData.append(
        "stripeAccountId",
        process.env.REACT_APP_ENVIROMENT === "test"
          ? stripeAccount
          : stripeAccounts[0]?.accountId,
      );
      formData.append("image", image);
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";

        const response = await axios.post(`${beUrl}/makeListing`, formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response:", response);
        if (response.status === 201) {
          toast.success(response.data.message);
          resetForm();
          setTimeout(() => navigate("/userListings"), 2000);
        }
      } catch (error) {
        console.log("Hello: ", error);
        toast.error(error.response.data.error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const stripeAccountHandler = async (e) => {
    e.preventDefault();
    try {
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://coursebidder-9lj7.onrender.com";
      const response = await axios.get(
        `${beUrl}/create-stripe-account?session=${session}`,
        {
          withCredentials: true,
        },
      );
      if (response.data?.url) {
        window.location.href = response.data.url;
      }
      console.log("Response:", response);
      if (response.status === 201) {
        toast.success("Stripe Account");
      }
    } catch (error) {
      console.log("Hello: ", error);
      toast.error(error.response.data.error);
    }
  };
  if (loading) {
    return (
      <div>
        <Breadcrumbs
          title="Add New Listing"
          undercard="Create a new listing for your items"
        />
        <div className="w-full flex items-center justify-center py-20">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (

    <div className="max-w-md mx-auto px-4 py-6">
  <Breadcrumbs
    title="Add New Listing"
    undercard="Create a new listing for your items"
    className="text-center text-xl font-bold mb-6"
  />
  {stripeAccounts?.length > 0 ? (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-gray-700 text-lg font-medium mb-2">College Name</label>
        <select
          value={collegeName}
          onChange={(e) => setCollegeName(e.target.value)}
          className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        >
          <option value="" disabled>Select a college</option>
          {colleges.colleges.map((college, index) => (
            <option key={index} value={college}>{college}</option>
          ))}
        </select>
      </div>
      <div>
        <label className="block text-gray-700 text-lg font-medium mb-2">Are you offering to hold a class or listing a specific class to sell?</label>
        <select
          value={isClassListing}
          onChange={(e) => setIsClassListing(e.target.value === "true")}
          className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value={false}>Offering to Hold Class</option>
          <option value={true}>Listing Specific Class</option>
        </select>
      </div>
      {isClassListing && (
        <div>
          <label className="block text-gray-700 text-lg font-medium mb-2">Class Name</label>
          <input
            type="text"
            value={className}
            onChange={(e) => setClassName(e.target.value)}
            className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required={isClassListing}
          />
        </div>
      )}
      <div>
        <label className="block text-gray-700 text-lg font-medium mb-2">Price</label>
        <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-lg text-gray-700">$</span>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full pl-8 px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
      </div>
      <div>
        <label className="block text-gray-700 text-lg font-medium mb-2">Notes (Lecture/Discussion Number, Special Enrollment/Major Priorities, etc):</label>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="3"
        />
      </div>

      {/* Conditional Rendering Based on Environment */}
      {process.env.REACT_APP_ENVIROMENT === "test" ? (
        <>
          {stripeAccounts && (
            <div className="space-y-4">
              <div className="mb-4">
                <label className="text-gray-700 text-lg font-medium mb-2">Do you want to add new Stripe Account?</label>
                <input
                  type="checkbox"
                  checked={allowedNewAccount}
                  className="sr-only peer m-2"
                  onChange={toggleHandler}
                />
                <div className="m-2 dark:bg-gray-700 relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </div>
              {!allowedNewAccount && (
                <div>
                  <label className="block text-gray-700 text-lg font-medium mb-2">Choose Stripe Accounts</label>
                  <select
                    value={stripeAccount}
                    onChange={(e) => setStripeAccount(e.target.value)}
                    className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    {stripeAccounts.map((account, i) => (
                      <option key={i} value={account.accountId}>{account.accountId}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          )}
          {allowedNewAccount && (
            <div className="flex gap-2 mt-4">
              <label className="block text-gray-700 text-lg font-medium mb-2">Add new Stripe Account</label>
              <button
                onClick={(e) => stripeAccountHandler(e)}
                className="cursor-pointer font-medium text-base text-blue-600 hover:underline"
              >
                Create Stripe Account
              </button>
            </div>
          )}
        </>
      ) : (
        <div>
          <label className="block text-gray-700 text-lg font-medium mb-2">Current Stripe Account</label>
          <input
            type="text"
            disabled
            value={stripeAccounts[0]?.accountId || ""}
            placeholder="Account Id"
            className="w-full px-4 py-3 text-base border rounded-lg disabled:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
      )}

      {/* Sample Verification Image */}
      <div className="mt-4">
        <label className="block text-gray-700 text-lg font-medium mb-2">Sample Verification Image - Ensure your image shows current enrollment</label>
        <img
          src={sampleVerification}
          alt="Sample Verification"
          className="w-full max-w-xs mx-auto border rounded-lg mt-4"
        />
      </div>

      <div>
        <h4 className="text-red-500">{fileErrorMessage && fileErrorMessage}</h4>
        <label className="block text-gray-700 text-lg font-medium mb-2">Verification Image</label>
        {!image && (
          <input
            type="file"
            required
            value={image || ""}
            onChange={handlefile}
            className="w-full px-4 py-6 border rounded-lg border-dashed border-[#bfc1c4d6] focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        )}
      </div>
      <div className="border border-dashed rounded-md w-fit mx-auto mt-4">
        {image && (
          <div className="w-full h-auto border-[#bfc1c4d6]">
            <div className="flex justify-end m-2">
              <ImCross
                onClick={() => {
                  setImage(null);
                }}
                className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
              />
            </div>
            <Image
              imgSrc={URL.createObjectURL(image)}
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
        )}
      </div>

      <div>
        <button
          disabled={isSubmitting}
          type="submit"
          className={clsx(
            isSubmitting
              ? 'bg-gray-500 cursor-not-allowed '
              : 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800 cursor-pointer',
            'w-full px-6 py-3 text-white rounded-lg font-medium text-lg mt-4'
          )}
        >
          Create Listing
        </button>
      </div>
    </form>
  ) : (
    <motion.div
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
      className="flex flex-col justify-center items-center gap-4 pb-20"
    >
      <div className="max-w-[500px] p-8 bg-white flex gap-6 flex-col items-center rounded-md shadow-xl">
        <h1 className="font-titleFont text-xl font-bold uppercase text-center">
          No Stripe Account Connected
        </h1>
        <button
          onClick={(e) => stripeAccountHandler(e)}
          className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300"
        >
          Create Stripe Account
        </button>
      </div>
    </motion.div>
  )}
</div>

      );
};

export default AddListing;
