// src/pages/Faq.js
import React from "react";

const Faq = () => {
  return (
    <div className="w-full h-full p-8">
      <h1 className="text-3xl font-bold mb-4">
        Frequently Asked Questions (FAQ)
      </h1>
      <div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">
            1. What kind of payments does CourseBidder accept?
          </h2>
          <p>
            You can pay with your debit or credit card right now. We are working on adding PayPal transactions as well. 
          </p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">
            2. How can I be sure that I get the class or get paid when I sell a class?
          </h2>
          <p>
            The money is only released to the seller when both parties confirm that the class has been transacted. Else the payment will be refunded.
          </p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">
            3. How do I know my data is safe?
          </h2>
          <p>
            The CourseBidder app does not store any user data aside from classes listed. All payments are done through Stripe and no other data is asked for. 
          </p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">
            4. How do I buy a course?
          </h2>
          <p>Add it to your cart, select the times in which dropping/picking up is suitable for you, and checkout.</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">
            5. How long do I have to wait before the seller confirms. 
          </h2>
          <p>
            If 72 hours from your order go by without action from the seller, you will be refunded.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
