import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { logo } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import Flex from "../../designLayouts/Flex";
import { googleLogout, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateEmail } from "../../../redux/userDetails";
import { IoCartOutline } from "react-icons/io5";
import Cart from "../../modals/CartModel";

function signOut(email) {
  googleLogout(); // Log out of Google
  const beUrl = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : "https://server.coursebidder.com";

  return fetch(`${beUrl}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email }), // Include email in the request body
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Logout failed");
      }
      return response.json();
    })
    .then((data) => {
      document.cookie = "session=; Max-Age=0; path=/;"; // Clear the cookie manually
      window.location.href = "/home"; // Redirect to /home
      return data;
    })
    .catch((error) => {
      console.error("Error logging user out:", error);
      // Handle errors, like showing a message to the user
    });
}

function getProfile() {
  console.log("Hello User Data");
  const beUrl = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : "https://server.coursebidder.com";

  return fetch(`${beUrl}/getUserFromSession`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch profile");
      }
      return response.json();
    })
    .then((profileData) => {
      console.log("Üser Data: ", profileData);
      return profileData;
    })
    .catch((error) => {
      console.error("Error fetching profile:", error);
    });
}

const Header = ({ profileData, setProfileData }) => {
  const dispatch = useDispatch();
  const [navBarList, setNavBarList] = useState([]);
  const [cartEnable, setCartEnable] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    getProfile().then((data) => {
      setProfileData(data);
      dispatch(updateEmail(data?.email));
    });
  }, [dispatch]);

  useEffect(() => {
    const isLoggedIn = profileData && profileData.email;

    const tempNavBarList = [
      {
        _id: 1001,
        title: "Home",
        link: "/home",
        showIfLoggedOut: true,
        showIfLoggedIn: true,
      },
      {
        _id: 1002,
        title: "My Listings",
        link: "/userListings",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1003,
        title: "Approvals",
        link: "/approvals",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1004,
        title: "Add Listing",
        link: "/addListing",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1005,
        title: "Cart",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1006,
        title: "My Purchases",
        link: "/purchases",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1007,
        title: "Sign In with Google",
        link: "/home",
        showIfLoggedOut: true,
        showIfLoggedIn: false,
      },
      {
        _id: 1009,
        title: "Sign Out",
        link: "/home",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1008, // Make sure this ID is unique
        title: "FAQ",
        link: "/faq",
        showIfLoggedOut: true,
        showIfLoggedIn: true,
      },
    ];
    // Filter navBarList based on login status
    const updatedNavBarList = tempNavBarList.filter((item) => {
      if (isLoggedIn && item?.title === "Approvals") {
        return profileData?.isAdmin;
      }
      if (isLoggedIn && item?.title === "Sales") {
        return (
          profileData?.purchasedListings?.filter(
            ({ sellerID }) => sellerID === profileData?._id,
          )?.length > 0
        );
      }
      if (isLoggedIn && item.showIfLoggedIn) return true;
      if (!isLoggedIn && item.showIfLoggedOut) return true;
      return false;
    });

    setNavBarList(updatedNavBarList);
  }, [profileData]);

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const { credential } = response;
      console.log("Google credential received:", credential); // Log credential
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://server.coursebidder.com";
      const res = await axios.post(
        `${beUrl}/google-login`,
        { token: credential },
        { withCredentials: true },
      );
      console.log("Google login response status:", res.status); // Log response status
      console.log("Google login response data:", res.data); // Log response data

      if (
        res.status === 201 &&
        res.data.message === "Google login successful"
      ) {
        window.location.href = "/home"; // Redirect to /home
      } else {
        console.error(
          "Google login failed with status:",
          res.status,
          "and message:",
          res.data.message,
        );
      }
    } catch (error) {
      console.error("Error with Google login:", error);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login error:", error);
  };

  return (
    <div className="w-full h-28 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200">
      {cartEnable && (
        <div className="duration-1000">
          <Cart
            cartEnable={cartEnable}
            setCartEnable={setCartEnable}
            setProfileData={setProfileData}
            profileData={profileData}
          />
        </div>
      )}
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <Flex className="flex items-center justify-between h-full">
          <Link to="/home">
            <div>
              <Image className="w-60 object-cover" imgSrc={logo} />
            </div>
          </Link>
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-2xl"
            >
              &#9776;
            </button>
          </div>
          <div className={`menu-items ${isMobileMenuOpen ? "open" : ""}`}>
            <motion.ul
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col md:flex-row items-center w-auto z-50 p-0 gap-2"
            >
              <>
                {navBarList.map(({ _id, title, link }) => (
                  <NavLink
                    key={_id}
                    to={link}
                    className="flex font-normal hover:font-bold w-30 h-6 justify-center items-center px-6 text-xl text-black hover:underline underline-offset-[4px] decoration-[1px] hover:text-darkpurple md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                    state={{ data: location.pathname.split("/")[1] }}
                    activeClassName="active"
                    onClick={(e) => {
                      if (title === "Sign Out") {
                        e.preventDefault();
                        signOut(profileData.email);
                      }

                      setIsMobileMenuOpen(false); // Close the menu when a new page is clicked
                    }}
                  >
                    {title === "Sign In with Google" ? (
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        onError={handleGoogleLoginFailure}
                        render={({ onClick, disabled }) => (
                          <button
                            onClick={onClick}
                            disabled={disabled}
                            className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg transform transition-transform duration-300"
                          >
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                              alt="Google logo"
                              className="w-6 h-6 mr-2"
                            />
                            {title}
                          </button>
                        )}
                      />
                    ) : title === "Cart" ? (
                      <div className="relative">
                        <button
                          onClick={() => setCartEnable(!cartEnable)}
                          className="font-normal hover:font-bold"
                        >
                          {profileData?.cart?.length > 0 && (
                            <p className="absolute top-0.5 -right-8 md:-top-5 md:right-0 text-sm leading-tight px-1 rounded-full w-6 h-6 flex items-center justify-center text-white bg-blue-500 font-normal hover:font-normal">
                              {profileData?.cart?.length}
                            </p>
                          )}
                          Cart
                          {/* <IoCartOutline /> */}
                        </button>
                      </div>
                    ) : (
                      <li>{title}</li>
                    )}
                  </NavLink>
                ))}
              </>
            </motion.ul>
          </div>
        </Flex>
      </nav>
    </div>
  );
};

export default Header;
