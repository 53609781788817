import React from "react";

const ThankYouPage = () => {
  const styles = {
    container: {
      maxWidth: "600px",
      margin: "0 auto",
      padding: "40px",
      textAlign: "center",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
    },
    heading: {
      fontSize: "2em",
      marginBottom: "20px",
      color: "#28a745",
    },
    message: {
      fontSize: "1.2em",
      marginBottom: "20px",
    },
    link: {
      fontSize: "1em",
      color: "#007bff",
      textDecoration: "none",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>
        Thank you for purchasing through CourseBidder!
      </h1>
      <p style={styles.message}>
        Please reference your Purchases page for updates.
      </p>
      <a href="/purchases" style={styles.link}>
        Go to Purchases Page
      </a>
    </div>
  );
};

export default ThankYouPage;
